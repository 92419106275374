import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import Carousel from "./carousels_controller";

const application = Application.start();
const context = require.context("../controllers", true,  /^(?!(index\.js)).*\.js$/);
application.load(definitionsFromContext(context));
application.debug = true;


application.handleError = (error, message, detail) => {
    console.warn(message, detail)

}
window.Stimulus = application