// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '@fortawesome/fontawesome-free/js/all'
import { createConsumer } from "@rails/actioncable";
import toastr from "toastr"
import { v4 as uuidv4 } from 'uuid';
import moment from  'moment'
import axios from 'axios';

import 'controllers';
import './init.js';
import {PeerConnection} from "../utils/PeerConnection";
import '../stylesheets/application.tailwind.scss'

window.Actioncable = {
    createConsumer
}
window.toastr = toastr
window.uuidv4 = uuidv4
window.moment = moment
window.axios = axios
window.PeerConnection = PeerConnection
window.cable = createConsumer();

import './payment_options'

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('payment-options') && typeof window.initializePaymentOptions === 'function') {
    window.initializePaymentOptions();
  }
});