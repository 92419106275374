window.initializePaymentOptions = function() {
  const paymentOptionsContainer = document.getElementById('payment-options');
  const addPaymentOptionBtn = document.getElementById('add-payment-option');

  if (paymentOptionsContainer && addPaymentOptionBtn) {
    addPaymentOptionBtn.addEventListener('click', () => {
      const timestamp = new Date().getTime();
      const template = document.createElement('template');
      template.innerHTML = `
        <div class="nested-fields bg-gray-100 p-4 mb-4 rounded-lg" data-payment-option-id="new_${timestamp}">
          <div class="field mb-4">
            <label for="item_payment_options_attributes_${timestamp}_down_payment" class="block text-sm font-medium text-gray-700 mb-1">Down payment</label>
            <input type="number" step="0.01" name="item[payment_options_attributes][${timestamp}][down_payment]" id="item_payment_options_attributes_${timestamp}_down_payment" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
          </div>
          <div class="field mb-4">
            <label for="item_payment_options_attributes_${timestamp}_monthly_payment" class="block text-sm font-medium text-gray-700 mb-1">Monthly payment</label>
            <input type="number" step="0.01" name="item[payment_options_attributes][${timestamp}][monthly_payment]" id="item_payment_options_attributes_${timestamp}_monthly_payment" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
          </div>
          <div class="field mb-4">
            <label for="item_payment_options_attributes_${timestamp}_term" class="block text-sm font-medium text-gray-700 mb-1">Term (in months)</label>
            <input type="number" name="item[payment_options_attributes][${timestamp}][term]" id="item_payment_options_attributes_${timestamp}_term" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
          </div>
          <button type="button" class="remove-payment-option text-red-600 hover:text-red-800 font-medium" data-action="remove-payment-option">Remove Payment Option</button>
          <input type="hidden" name="item[payment_options_attributes][${timestamp}][_destroy]" id="item_payment_options_attributes_${timestamp}__destroy" value="false">
        </div>
      `;
      paymentOptionsContainer.appendChild(template.content.firstElementChild);
    });

    paymentOptionsContainer.addEventListener('click', (event) => {
      if (event.target.dataset.action === 'remove-payment-option') {
        const paymentOptionElement = event.target.closest('[data-payment-option-id]');
        if (paymentOptionElement) {
          const paymentOptionId = paymentOptionElement.dataset.paymentOptionId;
          if (paymentOptionId.startsWith('new_')) {
            paymentOptionElement.remove();
          } else {
            paymentOptionElement.style.display = 'none';
            const destroyInput = paymentOptionElement.querySelector('input[name$="[_destroy]"]');
            if (destroyInput) {
              destroyInput.value = 'true';
            }
          }
        }
      }
    });
  }
};