import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
    static targets = ["slide"]
    static values = {
        index: Number,
        lastindex: Number
    }

    //static classes = [ "currentSlide" ]
   connect(){
        const slide = this.targets.find("slide");
        this.divElements = slide.getElementsByTagName("div");
        this.lastindexValue = this.divElements.length > 0 ? this.divElements.length - 1 : 0;
        this.indexValue = 0;
   };
    disconnect(){

    }

    next() {
        if (this.indexValue < this.lastindexValue) {
            this.indexValue++;
        } else {
            this.indexValue = 0;
        }
    }
    previous() {
        if (this.indexValue > 0) {
            this.indexValue--
        } else {
            this.indexValue = this.lastindexValue
        }
    }
    indexValueChanged(value, previousValue) {

        this.render()
    }
    render() {
        let position = 0
        if(this.indexValue > 0) {
            for (let i = 0; i <= this.indexValue - 1; i++) {
                position += this.divElements[i].offsetWidth
            }
        }

        const slide = this.targets.find("slide");
        slide.style.left = "-" + position + "px";
    }
    get lastIndex() {
        return this.slideTargets.length - 1
    }
}