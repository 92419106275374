import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

// Create a global object to track initialization status
window.railsInitialized = window.railsInitialized || {
  ujs: false,
  turbolinks: false,
  activeStorage: false
};

document.addEventListener('DOMContentLoaded', () => {
  if (!window.railsInitialized.ujs) {
    Rails.start()
    window.railsInitialized.ujs = true;
  }
  if (!window.railsInitialized.turbolinks) {
    Turbolinks.start()
    window.railsInitialized.turbolinks = true;
  }
  if (!window.railsInitialized.activeStorage) {
    ActiveStorage.start()
    window.railsInitialized.activeStorage = true;
  }
})

// Export Rails for use in other files if needed
window.Rails = Rails;