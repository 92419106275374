import toastr from "toastr";
import { v4 as uuidv4 } from 'uuid';
export class PeerConnection {
    peerConnection;
    maxRetryCount = 3;
    maxDelaySec = 4;
    id = "";
    session_image_url = 'https://openvialabelimages2.s3.us-east-2.amazonaws.com/leasing_agent.png'
    peerConnectionListeners;
    statsIntervalId;
    talkVideoEle = null;
    idleVideoSrc='/assets/idle'

    constructor(options) {
        const {talkVideoId, session_image_url, idle_video_src} = options;
        console.debug("constructing PeerConnection Class")
        this.RTCPeerConnection = (
            window.RTCPeerConnection ||
            window.webkitRTCPeerConnection ||
            window.mozRTCPeerConnection
        ).bind(window);

        this.peerConnectionListeners = [
            {name: 'icegatheringstatechange', f: this.onIceGatheringStateChange, options: true},
            {name: 'icecandidate', f: this.onIceCandidate, options: true},
            {name: 'iceconnectionstatechange', f: this.onIceConnectionStateChange, options: true},
            {name: 'connectionstatechange', f: this.onSignalingStateChange, options: true},
            {name: 'signalingstatechange', f: this.onSignalingStateChange, options: true},
            {name: 'track', f: this.onTrack, options: true}
        ]
        this.id = uuidv4();
        this.session_image_url = session_image_url || this.session_image_url;
        this.idleVideoSrc = idle_video_src ? idle_video_src : '/assets/waiting3.mp4'
        this.talkVideoId = talkVideoId || 'talk-video';
        this.talkVideoEle = document.getElementById(this.talkVideoId);
        this.talkVideoEle.setAttribute('playsinline', '');
        this.init();
    }

    init = async () => {
        try {
            await this.fetchSession()
            await this.createPeerConnection()
            await this.createSdp()
        } catch (err) {
            this.stopAllStreams()
            this.closePeerConnection()
            console.info(err)
            toastr.error(err.message)
        }
    }
    stopAllStreams = () => {
        if (this.talkVideoEle.srcObject) {
            this.talkVideoEle.srcObject.getTracks().forEach((track) => track.stop());
            this.talkVideoEle.srcObject = null;
        }
    }
    closePeerConnection = () => {
        const pc = this.peerConnection
        if (!pc) {
            return
        }
        pc.close()
        this.peerConnectionListeners.forEach(({name, f, options}) => {
            pc.removeEventListener(name, f, options)
        })
        this.peerConnection = null
    }
    fetchSession = async () => {
        console.info("FetchSession Called")
        const req = await fetch("/api/did/streams/session", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        const json = await req.json()
        this.session = json
        return this.session;
    }
    createPeerConnection = async () => {
        try {
        if (!this.session) {
            return console.info("No session created")
        }
        const {ice_servers:iceServers, offer} = this.session
        if (!this.peerConnection) {
            this.peerConnection = new this.RTCPeerConnection({iceServers});
            this.peerConnectionListeners.forEach(({name, f, options}) => {
                this.peerConnection.addEventListener(name, f, options);
            })
        }

        await this.peerConnection.setRemoteDescription(offer)
        const answer = await this.peerConnection.createAnswer()
        await this.peerConnection.setLocalDescription(answer)
        this.connection = answer;
        return answer;}
        catch(err){throw err}
    }
    createSdp = async () => {
        try {
        return await fetch(`/api/did/streams/${this.session.id}/sdp`, {
            method: 'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body: JSON.stringify({session_answer: this.connection, session_id: this.session.session_id})
        })}
        catch(err){throw err}
    }
    onIceGatheringStateChange = () => {
        console.debug("onIceGatheringStateChange")
        const event = new CustomEvent("iceGatheringStateChange", {
            detail: {id: this.id, state: this.peerConnection.iceGatheringState},
            bubbles: true,
            cancelable: true,
            composed: false,
        })
        window.dispatchEvent(event)
    }
    iceCandidateQueue = []
    icCandidateTimer = setInterval(async ()=>{
        const event = this.iceCandidateQueue.pop()
        if(event){
           await this.iceCandidateHandler(event)
        }
    },50)
    iceCandidateHandler = async (event) => {
        const {candidate, sdpMid, sdpMLineIndex} = event.candidate;
        await fetch(`/api/did/streams/${this.session.id}/ice`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                candidate,
                sdpMid,
                sdpMLineIndex,
                session_id: this.session.session_id,
            })
        })
    }
    onIceCandidate = async (event) => {
        console.debug("onIceCandidate")
        if (event.candidate) {
            this.iceCandidateQueue.push(event)

        }
    }
    onTrack = (event) => {
        console.debug("onTrack - Event received");

        if (!event.track) {
            console.debug("onTrack - No track in event");
            return;
        }

        console.debug("onTrack - Setting up stats interval");
        this.statsIntervalId = setInterval(async () => {
            // console.debug("onTrack - Interval triggered, getting stats");
            const stats = await this.peerConnection.getStats(event.track);

            stats.forEach((report) => {
                // console.debug("onTrack - Processing report", report.type, "MEDIA TYPE:", report.kind, Object.keys(report));

                if (report.type === 'inbound-rtp' && report.kind === 'video') {
                    // console.debug("onTrack - Report is inbound RTP for video");

                    const videoStatusChanged = this.videoIsPlaying !== (report.bytesReceived > this.lastBytesReceived);
                    // console.debug(`onTrack - Checking video status change: ${videoStatusChanged}`);

                    if (videoStatusChanged) {
                        this.videoIsPlaying = report.bytesReceived > this.lastBytesReceived;
                        // console.debug("onTrack - Video status changed:", this.videoIsPlaying);

                        // console.debug("onTrack - Invoking onVideoStatusChange with stream", event.streams[0]);
                        this.onVideoStatusChange(event.streams[0]);
                    }

                    this.lastBytesReceived = report.bytesReceived;
                    // console.debug("onTrack - Updated lastBytesReceived:", this.lastBytesReceived);
                }
            });
        }, 500);

        // console.debug("onTrack - Interval set up complete");
    }

    onIceConnectionStateChange = () => {
        console.debug("onIceConnectionStateChange")
        const event = new CustomEvent("iceConnectionStateChange", {
            detail: {id: this.id, state: this.peerConnection.iceConnectionState},
            bubbles: true,
            cancelable: true,
            composed: false,
        })
        window.dispatchEvent(event)
        if (['failed', 'closed'].includes(this.peerConnection.iceConnectionState)) {
            this.stopAllStreams();
            this.closePeerConnection();
        }
    }
    onSignalingStateChange = () => {
        console.debug("onsignalingStateChange");
        console.log("CONNECTION STATE:", this.peerConnection.connectionState)
        const event = new CustomEvent("signalingStateChange", {
            detail: {id: this.id, state: this.peerConnection.signalingState},
            bubbles: true,
            cancelable: true,
            composed: false,
        })
        window.dispatchEvent(event)
    }
    onConnectionStateChange = () => {
        console.debug("onConnectionStateChange")
        const event = new CustomEvent("connectionStateChange", {
            detail: {id: this.id, state: this.peerConnection.connectionState},
            bubbles: true,
            cancelable: true,
            composed: false,
        });
        window.dispatchEvent(event)
    }
    onVideoStatusChange = (stream) => {
        console.info("VIDEO STATUS CHANGED", this.videoIsPlaying)

        let status;
        if (this.videoIsPlaying) {
            status = 'streaming';
            this.setVideoElement(stream);
        } else {
            status = 'empty';
            this.playIdleVideo();
            console.log("IDLE VIDEO SHOULD BE PLAYING")
        }
        console.debug("OnVideoStatusChange: ", "Status => ", status)
    }
    setVideoElement = async (stream) => {
        if (!stream) {
            console.debug("Set Video Element: No Stream Available")
            return;
        }
        this.talkVideoEle.srcObject = stream;
        this.talkVideoEle.loop = false;
        // safari hotfix
        console.log(this.talkVideoEle.paused)
        if (this.talkVideoEle.paused) {
            this.talkVideoEle.play()
                .then((_) => {
                    console.debug("setVideoElement: ", "Video Playing from safari HotFix")
                })
                .catch((e) => {
                });
        } this.talkVideoEle.play()
    }
    setVideoElementSrc = async (url) => {
        try {
            this.talkVideoEle.srcObject = undefined
            this.talkVideoEle.src = url
            this.talkVideoEle.loop = false
            await this.talkVideoEle.play()
        } catch (err) {
            console.debug(err)
            throw new Error("Unable to play video")
        }
    }
    generateAndPlayVideo = async (content) => {
        console.info("Generate and Play Video")
        console.info("Session Id:", this.session.id)
        console.info("Session Session_Id:", this.session.session_id)

        try {
            console.log(this.peerConnection.signalingState, this.peerConnection.connectionState)
            if (this.peerConnection.signalingState === 'stable' || this.peerConnection.connectionState === 'connected') {
                const response = await fetch(`/api/did/streams/${this.session.id}/video`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        content,
                        session_id: this.session.session_id
                    })
                })
                if (!response.ok) {
                    throw new Error(`Request failed with status ${response.status}`);
                }

                const json = await response.json()
                if (json && json.videoUrl) {
                    const req = await this.setVideoElementSrc(json.videoUrl)
                    const json = await req.json()
                    await this.setVideoElementSrc(json.videoUrl)
                } console.log("generateAndPlayVideo:", json)
                return json
            }
        } catch (err) {
            console.info('video stream failed', err)
            throw err
        }
    }
    playIdleVideo = async () => {
        this.talkVideoEle.srcObject = undefined;
        this.talkVideoEle.src = this.idleVideoSrc;
        this.talkVideoEle.loop = true;
        if (this.talkVideoEle.paused) {
            await this.talkVideoEle.play().catch(err => console.log("VIDEO PLAY ERROR", err))
        }
    }
}